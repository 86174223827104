<template>
    <v-container
        id="site-footer"
        class="white--text  blue-grey darken-4 pt-12"
        fluid
        style="min-height:400px"
    >
        <v-row class="col-lg-8 col-xl-8 col-sm-12 col-md-12 mx-auto">
            <v-row>
                <v-col xl="4"
                       lg="4"
                       md="6"
                       sm="6"
                       cols="12">
                    <router-link
                        link
                        exact
                        :to="{path:'/'}">
                        <v-img
                            max-height="54px"
                            max-width="200px"
                            src="/img/Kaboozt_logo.svg"
                            contain
                            class="mb-4"
                        />
                    </router-link>
                    <v-divider class="mt-7"/>
                    <div class="footerInfo subtitle-2 grey--text text--lighten-2 py-4" v-html="content[$store.state.locale].block1.text"></div>

                </v-col>
                <v-col xl="4"
                       lg="4"
                       md="6"
                       sm="6"
                       cols="12"
                       class=" pt-10">
                    <p class="footer-header">{{content[$store.state.locale].block2.header}}</p>
                    <v-divider class="mt-4 mb-3"/>
                    <router-link :to="{path:'/cookies'}" class="mr-4  subtitle-2 grey--text text--lighten-2" light>
                        Cookies
                    </router-link>
                    <br>
                    <router-link :to="{path:'/terms'}" class="mr-4  subtitle-2 grey--text text--lighten-2" light>
                        {{content[$store.state.locale].block2.termsAnchor}}
                    </router-link>
                    <br>
                    <router-link :to="{path:'/blog'}" text class="mr-4  subtitle-2 grey--text text--lighten-2">Blog</router-link>
                    <div class="mt-6">
                        <a href="https://www.facebook.com/profile.php?id=100087576590060" target="_blank" style="text-decoration: none;">
                            <v-icon dark class="mr-4">mdi-facebook</v-icon>
                        </a>
                        <a href="https://www.linkedin.com/company/88939259" target="_blank" style="text-decoration: none;">
                            <v-icon dark>mdi-linkedin</v-icon>
                        </a>
                    </div>
                </v-col>
                <v-col xl="4"
                       lg="4"
                       md="6"
                       sm="6   "
                       cols="12"
                       class="pt-10">
                    <p class="footer-header">{{content[$store.state.locale].block3.header}}</p>
                    <v-divider class="my-4"/>
                    <newsletter-form/>
                </v-col>
            </v-row>
        </v-row>
        <v-row>
            <v-col cols="8"
                   class="mx-auto">
                <div class="d-flex flex-wrap align-content-center justify-center mt-4 pb-8 fpUSP"
                     style="column-gap: 4em">
                    <img src="/img/semrush-vector-logo.svg"
                         height="36"
                         alt="Semrush logo"/>
                    <img src="/img/ahrefs-logo.svg"
                         height="42"
                         style="margin-top:-4px"
                         alt="ahrefs logo"/>
                    <img src="/img/google-search-console_logo.svg"
                         height="42"
                         style="margin-top:-5px"
                         alt="Google Analytics logo"/>
                    <img src="/img/accuranker_logo_fix.svg"
                         height="36"
                         style="margin-top:0"
                         alt="Accuranker logo"/>
                    <!--img src="/img/google.png" height="36" style="margin-top:0px"/-->
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import mailService from "@/services/mailService";
    import NewsletterForm from "@/components/forms/newsletterForm";

    export default {
        name: "siteFooter",
        components: {NewsletterForm},
        data() {
            return {
                content: {
                    en: {
                        block1: {
                            header: '',
                            text: '<p><strong>Kaboozt ApS</strong></p>\n' +
                                '<p>VAT: DK-39807734</p>\n' +
                                '<br>\n' +
                                '<p><strong>Administration</strong></p>\n' +
                                '<p>Vestre Skovvej 1</p>\n' +
                                '<p>8240 Risskov</p>\n' +
                                '<br>\n' +
                                '<p><strong>Sales</strong></p>\n' +
                                '<p>Store Torv 5</p>\n' +
                                '<p>8000 Aarhus C</p>\n' +
                                '<br>\n' +
                                '<p>info@Kaboozt.com</p>\n' +
                                '<p>+45 4242 1899</p>' +
                                '<p class="blue-grey--text text--lighten-4 mt-4" style="font-size: 12px;">© 2022 Kaboozt - All rights reserved.</p>'
                        },
                        block2: {
                            header: 'Content',
                            termsAnchor: 'Terms and conditions',
                        },
                        block3: {
                            header: 'Newsletter',
                        }
                    },
                    da: {
                        block1: {
                            header: '',
                            text: '<p><strong>Kaboozt ApS</strong></p>\n' +
                                '<p>CVR: DK-39807734</p>\n' +
                                '<br>\n' +
                                '<p><strong>Administration</strong></p>\n' +
                                '<p>Vestre Skovvej 1</p>\n' +
                                '<p>8240 Risskov</p>\n' +
                                '<br>\n' +
                                '<p><strong>Salg</strong></p>\n' +
                                '<p>Store Torv 5</p>\n' +
                                '<p>8000 Aarhus C</p>\n' +
                                '<br>\n' +
                                '<p>info@Kaboozt.com</p>\n' +
                                '<p>+45 4242 1899</p>' +
                                '<p class="blue-grey--text text--lighten-4 mt-4" style="font-size: 12px;">© 2022 Kaboozt - All rights reserved.</p>'
                        },
                        block2: {
                            header: 'Indhold',
                            termsAnchor: 'Handelsbetingelser',
                        },
                        block3: {
                            header: 'Nyhedsbrev',
                        }
                    },
                },
                contact: {
                    email: '',
                    method: 'newsletterSignup'
                },
                loading: false,
                contactSuccess: false,
                invisibleRecaptcha: false,
                sitekey: '6Lfz3Y0iAAAAAE_CooC4T-QSoFLxcyyV17tN4uos',

            }
        },
        methods: {
            onSubmit() {
                this.$refs.invisibleRecaptcha.execute()
            },
            onVerify() {
                this.loading = true
                mailService.send(this.contact, 'newsletterSignup')
                    .then(response => {
                        if (response.data.message === 'mail-service/mail-sent') {
                            this.loading = false;
                            this.contactSuccess = true;
                        }
                    })
            },
            onExpired() {
                console.log('Expired')
            },
            resetRecaptcha() {
                this.$refs.recaptcha.reset() // Direct call reset method
            },
        },
    }
</script>

<style>
    #site-footer {
    }

    .footerInfo p {
        font-weight: 400;
        font-size: 14px !important;
        line-height: 1.1 !important;
        margin-bottom: 0 !important;
    }

    .footerInfo p,.footerInfo strong {
    }

    .footer-header {
        font-size: 18px;
        font-weight: 600;
    }
    .fpUSP {
        row-gap: 25px;
    }

    .fpUSP img {

    }
</style>