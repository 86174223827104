<template>
    <v-container class="">
        <v-breadcrumbs
            v-if="!$store.state.auth.status.authorized "
            :item="items"
        >
            <template v-slot:divider>
                <v-icon>mdi-forward</v-icon>
            </template>
            <v-breadcrumbs :items="items">
                <template v-slot:divider>
                    <v-icon>mdi-chevron-right</v-icon>
                </template>
            </v-breadcrumbs>
        </v-breadcrumbs>
    </v-container>
</template>

<script>
    export default {
        name: "breadcrumbs",
        data() {
            return {
                items: [
                    {
                        text: 'Dashboard',
                        disabled: false,
                        href: 'breadcrumbs_dashboard',
                    },
                ]
            }
        },
        mounted() {
            this.breadCrumbs()
        },
        methods: {
            breadCrumbs() {
                this.items = []
                this.items.push({
                    text: 'Home',
                    disabled: false,
                    href: '/',
                })
                this.$router.currentRoute.path.split('/').forEach((path,key) => {
                    this.items.push({
                        text: path,
                        disabled: false,
                        href: '/' + path,
                    })
                })


            }
        },
        watch: {
            '$route.path': {
                handler() {
                    this.breadCrumbs()
                },
                immediate: true,
                deep: true,
            }


        }
    }
</script>

<style scoped>

</style>