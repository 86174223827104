<template>
    <div id="languageSwitch">
        <v-menu offset-y>
            <template v-slot:activator="{ on , attrs}">
                <v-btn
                    icon
                    fab
                    v-bind="attrs"
                    v-on="on"
                    color="#fff"
                >
                    <v-icon>mdi-web</v-icon>
                </v-btn>
            </template>
            <v-sheet dark>
                <v-list dense>
                    <v-list-item link href="https://kaboozt.com/" class="px-8">
                        <v-list-item-title>English</v-list-item-title>
                    </v-list-item>
                    <v-list-item link href="https://kaboozt.dk/" class="px-8">
                        <v-list-item-title>Dansk</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-sheet>
        </v-menu>
    </div>
</template>

<script>
    export default {
        name: "localeSwitch",
        data() {
            return {}
        },
        methods: {
            updateLanguage() {
                let url = new URL(window.location)
                let tld = url.host.split('.').pop()
                switch (tld) {
                    case 'dk':
                        this.$store.state.locale = 'da'
                        break;
                    case 'com':
                        this.$store.state.locale = 'en'
                        break;
                    default:
                        this.$store.state.locale = 'en'
                        break;
                }
                document.documentElement.setAttribute("lang", this.$store.state.locale);

            }
        },
        mounted() {
            this.updateLanguage()
        }
    }
</script>

<style scoped>
    #languageSwitch {
    }

</style>