import { render, staticRenderFns } from "./notificationBadge.vue?vue&type=template&id=2e5b00a5&scoped=true"
import script from "./notificationBadge.vue?vue&type=script&lang=js"
export * from "./notificationBadge.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e5b00a5",
  null
  
)

export default component.exports