import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import auth from "../store/authModule";
import toasts from './toastModule'
import cartModule from "@/store/cartModule";
import plansPricing from "@/store/plansPricing";

export default new Vuex.Store({
    state: {
        nav: {
            drawer:{
                show: false,
                mini: false,
            },
        },
        page: {
            meta: {
                title: ''
            },
            theme:{
                dark:false
            }
        },
        dataTables: {
            breakPoint: 0
        },
        pageState:200,
        locale:'en'
    },
    mutations: {},
    actions: {},
    modules: {
        auth,
        toasts,
        cartModule,
        plansPricing,
    },

    watch: {
        $route(to, from) {
            this.state.page = this.$router.currentRoute
        }
    }
})
