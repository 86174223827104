<template>
    <v-navigation-drawer
        app
        :mini-variant="$store.state.nav.drawer.mini"
        dark
        v-model="$store.state.nav.drawer.show"
        class="hidden-md-and-up"
        disable-resize-watcher
    >
        <template v-slot:prepend>
            <v-list-item>
                <v-list-item-content>
                    <router-link
                        link
                        exact
                        :to="{path:'/'}">
                        <v-img
                            max-height="28px"
                            max-width="200px"
                            src="/img/kaboozt_logo.svg"
                            contain
                        ></v-img>
                    </router-link>
                </v-list-item-content>
            </v-list-item>
            <v-divider/>
        </template>

        <v-list-item v-if="$store.state.auth.authorized">
            <v-list-item-avatar color="red">
                {{initials}}
            </v-list-item-avatar>
            <v-list-item-content class="caption">
                <v-list-item-title>
                    <router-link :to="{path:'din-side'}">{{$store.state.auth.user.name}}</router-link>
                </v-list-item-title>
                {{$store.state.auth.user.mail}}
            </v-list-item-content>
        </v-list-item>

        <v-list dense>
            <v-subheader>Menu</v-subheader>
            <v-list-item
                v-for="route in content[$store.state.locale].anchors"
                :key="route.name"
                :to="{path:'/',hash:route.anchor}"
                exact
                link
            >
                <v-list-item-title>{{route.title}}</v-list-item-title>
            </v-list-item>
            <v-list-item
                :to="{path:'/blog'}"
                exact
                link
            >
                <v-list-item-title>BLOG</v-list-item-title>
            </v-list-item>

        </v-list>


        <template v-slot:append>
            <v-list class="pa-0" dense shaped>

                <v-divider/>
                <v-list-item exact link href="https://kaboozt.com/">
                    <v-list-item-avatar size="20" tile>
                        <v-icon>mdi-web</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-title>English</v-list-item-title>
                </v-list-item>
                <v-list-item exact link href="https://kaboozt.dk/">
                    <v-list-item-avatar size="20" tile>
                        <v-icon>mdi-web</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-title>Dansk</v-list-item-title>
                </v-list-item>

                <v-list-item
                    class="border-top-light"
                    exact
                    link
                    :to="{path:'/',hash:'contact'}"
                >
                    <v-list-item-avatar size="20" tile>
                        <v-icon>mdi-login</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title>{{ content[$store.state.locale].signUpText}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <!--v-list class="pa-0" dense shaped v-if="$store.state.auth.status.loggedIn">
                <v-list-item @click="$store.dispatch('auth/logout')" class="border-top-light" exact link>
                    <v-list-item-avatar size="20" tile>
                        <v-icon>mdi-logout</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title>Logout</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <login-dialog v-if="!$store.state.auth.loggedIn"/-->
        </template>
    </v-navigation-drawer>
</template>

<script>
    import LoginDialog from "@/components/user/loginDialog";

    export default {
        name: "navigationDrawer",
        components: {LoginDialog},
        data() {
            return {
                currentPath: this.$router.currentRoute.path,
                routes: [],
                content: {
                    en: {
                        anchors: [
                            {title: 'HOW IT WORKS', anchor: '#howItWorks',},
                            {title: 'LINKBUILDING', anchor: '#what-is-linkbuilding',},
                            {title: 'CONTENT', anchor: '#content-creation-by-industry-professionals',},
                            {title: 'PRICING', anchor: '#pricing',},
                            {title: 'ABOUT', anchor: '#about',},
                            {title: 'CONTACT', anchor: '#contact',},
                        ],
                        signUpText: 'Sign up'
                    },
                    da: {
                        anchors: [
                            {title: 'HVORDAN VIRKER DET', anchor: '#howItWorks',},
                            {title: 'LINKBUILDING', anchor: '#what-is-linkbuilding',},
                            {title: 'INDHOLD', anchor: '#content-creation-by-industry-professionals',},
                            {title: 'PRIS', anchor: '#pricing',},
                            {title: 'OM', anchor: '#about',},
                            {title: 'KONTAKT', anchor: '#contact',},
                        ],
                        signUpText: 'TILMELD'
                    }
                },
                options: {
                    duration: 500,
                    easing: 'easeInOutCubic',
                },

            }
        },
        methods: {
            navState() {
                if (this.breakpoint === 'lg' || this.breakpoint === 'xl') {
                    this.$store.state.nav.show = true
                }
            }
        },
        watch: {
            '$vuetify.breakpoint.name': {
                handler(name) {
                    if (['md', 'lg', 'xl'].includes(name)) {
                        this.$store.state.nav.show = false
                    }
                },
                deep: true,
                immediate: true,
            },
            'breakpoint': {
                handler() {
                    this.navState()
                }
            }
        },
        mounted() {
            this.navState()
        },
        created() {

        },
        computed: {
            currentCartCounter: function () {
                return this.$store.state.cartModule.orders.length
            },
            initials() {
                return ''
                let n = this.$store.state.auth.user.name
                return n.slice(0, 1) + n.split(' ')[n.split(' ').length - 1].slice(0, 1)
            },
            breakpoint: function () {
                return this.$vuetify.breakpoint.name
            }
        }

    }
</script>

<style scoped>
</style>
