const chosenPlan = JSON.parse(localStorage.getItem('chosenPlan'))
const initialState = chosenPlan
    ? {chosenPlan}
    : {plan: 0, DR: 10,}


export default {
    namespaced: true,
    state: {
        chosenPlan: 0,
        en: {
            header: 'LET US DO THE WORK',
            fromText: 'From',
            customPlan: 'CUSTOMIZE YOUR PLAN',
            currency: 'EUR',
            currencySymbol: '€',
            monthAbbr: 'mo.',
            plans: [
                {
                    name: 'BASIC',
                    price: 235,
                    prices: [
                        {dr: 10, price: 235},
                        {dr: 20, price: 249},
                        {dr: 30, price: 309},
                        {dr: 40, price: 339},
                        {dr: 50, price: 669},
                    ],
                    text: '2-3 links/month <br>High quality content included<br>Free support',
                    plan: 0,
                    DR: 10,
                    DRmin: 10,
                    DRmax: 50,
                    radio: false,
                    buttonText: 'Get started',
                }, {
                    name: 'BUSINESS',
                    price: 495,
                    prices: [
                        {dr: 20, price: 495},
                        {dr: 30, price: 619},
                        {dr: 40, price: 669},
                        {dr: 50, price: 1349},
                        {dr: 60, price: 1849},
                    ],
                    text: '4-6 links/month<br>High quality content included<br>Free support',
                    plan: 0,
                    DR: 20,
                    DRmin: 20,
                    DRmax: 60,
                    radio: false,
                    buttonText: 'Get started',
                }, {
                    name: 'PRO',
                    price: 1115,
                    prices: [
                        {dr: 30, price: 1115},
                        {dr: 40, price: 1195},
                        {dr: 50, price: 2419},
                        {dr: 60, price: 3329},
                        {dr: 70, price: 4839},
                    ],
                    text: '8-10 links/month<br>High quality content included<br>Free support',
                    plan: 0,
                    DR: 30,
                    DRmin: 30,
                    DRmax: 70,
                    radio: false,
                    buttonText: 'Get started',
                },
            ],
        },
        da: {
            header: 'LAD OS STÅ FOR DET HELE ',
            fromText: 'Fra',
            customPlan: 'TILPAS DIN PLAN',
            currency: 'DKK',
            currencySymbol: 'kr.',
            monthAbbr: 'mnd.',
            plans: [
                {
                    name: 'BASIS',
                    price: 1750,
                    prices: [
                        {dr: 10, price: 1750},
                        {dr: 20, price: 1875},
                        {dr: 30, price: 2295},
                        {dr: 40, price: 2495},
                        {dr: 50, price: 4995},
                    ],
                    text: '2-3 links / mnd. <br>Højkvalitets indhold<br>Fri support',
                    plan: 0,
                    DR: 10,
                    DRmin: 10,
                    DRmax: 50,
                    buttonText: 'KOM IGANG',
                }, {
                    name: 'BUSINESS',
                    price: 3750,
                    prices: [
                        {dr: 20, price: 3750},
                        {dr: 30, price: 4595},
                        {dr: 40, price: 4995},
                        {dr: 50, price: 9995},
                        {dr: 60, price: 13750},
                    ],
                    text: '4-6 links / mnd.<br>Højkvalitets indhold<br>Fri support',
                    plan: 0,
                    DR: 20,
                    DRmin: 20,
                    DRmax: 60,
                    buttonText: 'KOM IGANG',
                }, {
                    name: 'PRO',
                    price: 8295,
                    prices: [
                        {dr: 30, price: 8295},
                        {dr: 40, price: 8995},
                        {dr: 50, price: 17995},
                        {dr: 60, price: 24750},
                        {dr: 70, price: 35995},
                    ],
                    text: '8-10 links / mnd.<br>Højkvalitets indhold<br>Fri support',
                    plan: 0,
                    DR: 30,
                    DRmin: 30,
                    DRmax: 70,
                    buttonText: 'KOM IGANG',
                },
            ],
        }
    },
    mutations: {
        signUp(state, payload) {
            localStorage.setItem('subscribe', JSON.stringify(state.plans))
        },
    },
    actions: {
        signUp({commit}, payload) {
            commit('signUp', payload)
        },

    }
}