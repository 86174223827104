/**
 * Date object extensions
 * --------------------
 * Checks if a valid date is provided
 * Explanation:
 * An invalid date object returns NaN for getTime()
 * and NaN is the only object not strictly equal to itself.
 *
 * It's a hacky solution, but works wonders.
 *
 * @returns {boolean}
 */
Date.prototype.isValid = function () {
    return this.getTime() === this.getTime();
}

/**
 * Days From Now extensions
 * -----------------------
 * Simply takes a given date, adds 14 days and returns YYYY-mm-dd
 * @param {int} days
 * @returns {string} YYYY-mm-dd
 */
Date.prototype.daysFromNow = function (days) {
    let fn = new Date();
    fn.setDate(fn.getDate() + days);
    let month = (fn.getMonth() + 1).toString().length === 1 ? '0' + (fn.getMonth() + 1) : (fn.getMonth() + 1)
    return fn.getFullYear() + '-' + month + '-' + fn.getDate()
}

/**
 * Just-gimme-the-damn-numbers-like-i-want-them Polyfill
 * -----------------------------------------------------
 * * USAGE:
 * const d = new Date().toRegular()
 * console.log(`${c.d}-${c.m}-${c.Y} kl. ${c.H}:${c.i}:${c.s}`)
 * -----------------------------------------------------
 * @param addLeadingZero - Take a wild fucking guess
 * @returns {{s: number, d: number, H: number, Y: number, i: number, m: number}|boolean}
 */
Date.prototype.toRegular = function (addLeadingZero = true) {
    if (!this.isValid()) {
        return false
    }
    let dd = this.getDate(),
      mm = this.getMonth() + 1,
      yyyy = this.getFullYear(),
      h = this.getHours(),
      i = this.getMinutes(),
      s = this.getSeconds();
    if (addLeadingZero === true) {
        dd = dd < 10 ? '0' + dd : dd
        mm = mm < 10 ? '0' + mm : mm
        h = h < 10 ? '0' + h : h
        i = i < 10 ? '0' + i : i
        s = s < 10 ? '0' + s : s
    }
    return {d: dd, m: mm, Y: yyyy, H: h, i: i, s: s}
}

