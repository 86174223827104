import Vue from 'vue'
import VueRouter from 'vue-router'
import api from "../services/api";
import store from '../store/index'
import goTo from 'vuetify/lib/services/goto'

Vue.use(VueRouter)


let routes = [
    {
        name: "Forside",
        path: "/",
        meta: {
            en: {
                title: 'Kaboozt | Linkbuilding made easy',
            },
            da: {
                title: 'Kaboozt | Nem linkbuilding',
            },
            icon: "mdi-format-list-bulleted",
            parent: 0,
            guarded: false,
            naviHide: true,
        },
        component: () => import('@/views/Home.vue'),

    },
    {
        path: '/kurv',
        name: 'Kurv',
        meta: {
            en: {
                title: 'Cart | Kaboozt',
            },
            da: {
                title: 'Kurv | Kaboozt',
            },
            icon: 'mdi-account',
            naviHide: true,
            guarded: true,
        },
        component: () => import('@/views/Cart.vue'),
    },

    {
        path: '/orders',
        name: 'Orders',
        meta: {
            en: {
                title: 'Orders | Kaboozt',
            },
            da: {
                title: 'Bestillinger | Kaboozt',
            },
            title: 'Orders',
            icon: 'mdi-account',
            naviHide: false,
            guarded: true,
        },
        component: () => import('@/views/Orders.vue'),
    },
    {
        path: '/blog',
        name: 'Blog',
        meta: {
            en: {
                title: 'Linkbuilding blog | Kaboozt',
            },
            da: {
                title: 'Linkbuilding blog | Kaboozt',
            },
            icon: 'mdi-comment-processing',
            naviHide: true,
            guarded: false,
        },
        component: () => import('@/views/Blog.vue'),
    },
    {
        path: '/blog/:alias',
        name: 'Blog post',
        meta: {
            en: {
                title: 'Blog post | Kaboozt',
            },
            da: {
                title: 'Blogindlæg | Kaboozt',
            },
            icon: 'mdi-comment-processing',
            naviHide: true,
            guarded: false,
        },
        component: () => import('@/views/BlogPost.vue'),
    },
    {
        path: '/cookies',
        name: 'Cookies',
        meta: {
            en: {
                title: 'Cookie policy | Kaboozt',
            },
            da: {
                title: 'Cookiepolitik | Kaboozt',
            },
            icon: 'mdi-cookie',
            naviHide: true,
            guarded: false,
        },
        component: () => import('@/views/Cookies.vue'),
    },
    {
        path: '/content',
        name: 'Content',
        meta: {
            en: {
                title: 'Quality Content',
            },
            da: {
                title: 'Kvalitetsindhold',
            },
            icon: 'mdi-cookie',
            naviHide: true,
            guarded: false,
        },
        component: () => import('@/views/Content.vue'),
    },
    {
        path: '/linkbuilding',
        name: 'Linkbuilding',
        meta: {
            en: {
                title: 'Quality Content and Linkbuilding',
            },
            da: {
                title: 'Kvalitetsindhold og linkbuilding',
            },
            icon: 'mdi-cookie',
            naviHide: true,
            guarded: false,
        },
        component: () => import('@/views/Linkbuilding.vue'),
    },
    {
        path: '/terms',
        name: 'Terms and conditions',
        meta: {
            en: {
                title: 'Terms and condition | Kaboozt',
            },
            da: {
                title: 'Handelsbetingelser | Kaboozt',
            },
            icon: 'mdi-cookie',
            naviHide: true,
            guarded: false,
        },
        component: () => import('@/views/Terms.vue'),
    },
    {
        path: '/for-publishers',
        name: 'For Publishers',
        meta: {
            en: {
                title: 'For publishers - Join the worldwide Kaboozt publishing network | Kaboozt',
            },
            da: {
                title: 'Til udgivere - Tilslut dig et globalt udgivernetværk | Kaboozt',
            },
            icon: 'mdi-cookie',
            naviHide: true,
            guarded: false,
        },
        component: () => import('@/views/ForPublishers.vue'),
    },
    {
        path: '/digital-ads',
        name: 'Digital ads',
        meta: {
            en: {
                title: 'Digital Ads | Reach Millions of Customers with Digital Display Ads',
            },
            da: {
                title: 'Display annoncering | Nå millioner af kunder med digitale displayannoncer',
            },
            icon: 'mdi-cookie',
            naviHide: true,
            guarded: false,
        },
        component: () => import('@/views/DigitalAds.vue'),
    },
    {
        path: '/plans-pricing',
        name: 'Plans & pricing',
        meta: {
            en: {
                title: 'Plans & pricing | Start your linkbuilding journey today | Kaboozt',
            },
            da: {
                title: 'Priser | Start din linkbuildingrejse i dag | Kaboozt',
            },
            icon: 'mdi-money',
            naviHide: true,
            guarded: false,
        },
        component: () => import('@/views/PlansPricing_var.vue'),
    },
    {
        path: '/customize-plan',
        name: 'Customize plan',
        meta: {
            en: {
                title: 'Customize your linkbuilding journey today | Kaboozt',
            },
            da: {
                title: 'Begynd din linkbuildingrejse i dag | Kaboozt',
            },
            icon: 'mdi-money',
            naviHide: true,
            guarded: false,
        },
        component: () => import('@/views/customizePlan.vue'),
    },
    {
        path: '/auth',
        name: 'Account support',
        meta: {
            en: {
                title: 'Account support | Kaboozt',
            },
            da: {
                title: 'Kontosupport | Kaboozt',
            },
            icon: 'mdi-account',
            naviHide: true,
            guarded: false,
        },
        component: () => import('@/views/authActions.vue'),
    }, {
        name: "App",
        path: "/app",
        meta: {
            en: {
                title: 'Kaboozt | Linkbuilding made easy',
            },
            da: {
                title: 'Kaboozt | Linkbuilding made easy',
            },
            icon: "mdi-format-list-bulleted",
            naviHide: true,
            guarded: true,
        },
        component: () => import('@/views/Kaboozt.vue'),
    },
    {
        path: '/settings',
        name: 'settings',
        meta: {
            en: {
                title: 'Settings | Kaboozt',
            },
            da: {
                title: 'Indstillinger | Kaboozt',
            },
            icon: 'mdi-account',
            naviHide: true,
            guarded: true,
        },
        component: () => import('@/views/Settings.vue'),
    },
    {
        path: '/profile',
        name: 'My page',
        meta: {
            en: {
                title: 'My page | Kaboozt',
            },
            da: {
                title: 'Min side | Kaboozt',
            },
            icon: 'mdi-account',
            naviHide: true,
            guarded: true,
        },
        component: () => import('@/views/MyPage.vue'),
    },
    {
        path: '/admin',
        name: 'Admin',
        meta: {
            en: {
                title: 'Admin area | Kaboozt',
            },
            da: {
                title: 'Adminside | Kaboozt',
            },
            title: 'Admin area',
            icon: 'mdi-account',
            naviHide: false,
            guarded: true,
        },
        component: () => import('@/views/Admin.vue'),
    },
    {
        path: '/404',
        name: '404',
        meta: {
            en: {
                title: '404 | Page not found',
            },
            da: {
                title: '404 | Siden blev ikke fundet',
            },
            title: '404 page',
            icon: 'mdi-error',
            naviHide: true,
            guarded: false,
        },
        component: () => import('@/views/errorPages/error404.vue'),
    },
]


const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior: (to, from, savedPosition) => {
        let scrollTo = 0
        if (to.hash) {
            scrollTo = to.hash
        } else if (savedPosition) {
            scrollTo = savedPosition.y
        }
        return goTo(scrollTo)
    },
})

async function authorized(to) {
    return new Promise((resolve, reject) => {
        api.get('get-routes' + to.path)
            .then(response => {
                resolve(response)
            })
            .catch(err => {
                reject(err)
            })
    })
}

const DEFAULT_TITLE = 'Kaboozt | Linkbuilding made easy';
router.beforeResolve((to, from, next) => {
    if (to.name === null) {
        next('/404')
    }
    if (to.meta.guarded) {
        authorized(to).then(response => {
            switch (response.data.message) {
                case 'routes/user-authorized':
                    next()
                    break;
                case 'routes/user-unauthorized':
                    next(false)
                    break;
                default:
                    next(false)
            }
        })
            .catch(err => {
                if (err.data.message === 'routes/user-unauthorized') {
                    store.dispatch('toasts/toastError', 'Unauthorized access')
                }
                next('/')
            })
    } else {
        next()
    }
    Vue.nextTick(() => {
        document.title = to.meta[store.state.locale].title || DEFAULT_TITLE;
    });
});

export default router

