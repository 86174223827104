import api from './api.js' // <-- api.js (axios)
import {
    getAuth,
    signInWithPopup,
    signInWithEmailAndPassword,
    GoogleAuthProvider,
    sendPasswordResetEmail,
    updatePassword
} from "firebase/auth";
import store from "../store/index";

class AuthService {

    login(payload) {
        return new Promise((resolve, reject) => {
            const auth = getAuth()
            signInWithEmailAndPassword(auth, payload.email, payload.password)
                .then((userCredential) => {
                    resolve(userCredential.user)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    async authorize(user) {
        api.post('auth/authorize', user)
            .then(r => {
                console.log(r) // yay / nay
            })
    }

    /*
        signInPopup() {
            const googleAuthProvider = new GoogleAuthProvider()
            googleAuthProvider.setCustomParameters({
                prompt: 'select_account'
            });
            const auth = getAuth()
            return new Promise((resolve, reject) => {
                signInWithPopup(auth, googleAuthProvider)
                    .then(result => {
                        const credential = GoogleAuthProvider.credentialFromResult(result);
                        const token = credential.accessToken;
                        console.log(token)
                        // The signed-in user info.
                        const user = result.user;
                        // The signed-in user info.
                        console.log(user)
                        resolve(user)
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        }

     */



    logout() {
        getAuth().signOut()
        localStorage.removeItem('user')
    }
}

export default new AuthService()