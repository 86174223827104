import store from "./index";
import api from "../services/api";

const cart = JSON.parse(localStorage.getItem('cart'))
const initialState = cart
    ? {orders: cart, promo: '',}
    : {orders: [], promo: '',}




export default {
    namespaced: true,
    state: initialState,
    mutations: {
        addToCart(state, payload) {
            state.orders.push(payload)
            localStorage.setItem('cart', JSON.stringify(state.orders))
            store.dispatch('toasts/toastSuccess', payload.articles.length + ' presse' + (payload.articles.length === 1 ? '' : 'r') + ' tilføjet bestillingslisten.')
        },
        removeFromCart(state, orderId) {
            let orderKey = state.orders.findIndex(x => x.id == orderId)
            state.orders.splice(orderKey, 1)
            localStorage.setItem('cart', JSON.stringify(state.orders))
        },
        emptyCart(state, payload) {
            if (!!payload) {
                state.orders = []
            } else if (confirm('Tøm kurv?')) {
                state.orders = []
            }
            localStorage.setItem('cart', JSON.stringify(state.orders))
        },
        removeArticleFromCurrentOrder(state, orderId, articleId) {
            let orderKey = state.orders.findIndex(x => x.orderId == orderId)
            state.orders[orderKey].articles.splice(articleId, 1)
            localStorage.setItem('cart', JSON.stringify(state.orders))
        },
        confirmOrder(state) {
        }
    },
    actions: {
        addToCart({commit}, payload) {
            commit('addToCart', payload)
        },
        confirmOrder({commit, state}) {
            let payload = state
            state.user = store.state.auth.user
            return new Promise((resolve, reject) => {
                api.post('/orders', payload)
                    .then(response => {
                        if (response.data.status === 'success') {
                            switch (response.data.message) {
                                case 'store/order-confirmed':
                                    store.dispatch('toasts/toastSuccess', 'Bestilling modtaget')
                            }
                            commit('emptyCart', true) // skip confirmation
                            resolve('order-confirmed')
                        }
                        if (response.data.status === 'error') {
                            switch (response.data.message) {
                                case 'store/order-duplicate':
                                    store.dispatch('toasts/toastError', 'Bestilling allerede registreret')
                                    break;
                                case 'store/order-detail-duplicate':
                                    store.dispatch('toasts/toastError', 'Bestilling allerede registreret')
                                    break;
                                default:
                                    store.dispatch('toasts/toastError', 'Der er sket en ukent fejl, kontakt os for nærmere information.')
                            }
                            reject('order-failed')
                        }
                    })
                // let result = commit('confirmOrder')
            })
        },
        removeFromCart({commit}, id) {
            commit('removeFromCart', id)
        },
        removeArticleFromCurrentOrder({commit}, orderId, articleId) {
            commit('removeArticleFromCurrentOrder', orderId, articleId)
        },
        emptyCart({commit}, payload = false) {
            commit('emptyCart', payload)
        },
        addArticleToCurrentOrder({commit}) {
            commit('addArticleToCurrentOrder')
        },
        setArticleDate({commit}, payload) {
            commit('setArticleDate', payload)
        },
        prepareOrder({commit}, payload) {
            commit('prepareOrder', payload)
        }
    }
}