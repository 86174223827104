<template>
    <v-sheet color="transparent">
        <v-card-text class="pa-0">
            <div id="newsletter-form" v-if="!contactSuccess">
                <v-text-field :label="content[$store.state.locale].nameLabel" name="name" v-model="contact.name" dense outlined dark/>
                <v-text-field :label="content[$store.state.locale].emailLabel" name="email" v-model="contact.email" dense outlined dark/>
                <vue-recaptcha
                    ref="invisibleRecaptcha"
                    @verify="onVerify"
                    @expired="onExpired"
                    size="invisible"
                    :sitekey="sitekey"
                ></vue-recaptcha>
            </div>
            <div v-else class="text-center">
                <v-icon color="primary" x-large class="pa-4">mdi-email</v-icon>
                <div v-html="content[$store.state.locale].submitSuccess"></div>
            </div>
            <v-btn
                @click="onSubmit"
                :loading="loading"
                :disabled="loading"
                color="primary"
                width="100%"
            >
                <v-icon class="mr-2" small>mdi-email</v-icon>
                {{content[$store.state.locale].subscribeButton}}
            </v-btn>
        </v-card-text>
        <v-card-text
            class="grey--text grecaptcha-policies pt-0"
            style="font-size: 12px;"
        >
            This site is protected by reCAPTCHA and the Google
            <a href="https://policies.google.com/privacy" style="color: #aaa;" target="_blank">Privacy
                Policy</a> and
            <a href="https://policies.google.com/terms" style="color: #aaa;" target="_blank">Terms of
                Service</a> apply.
        </v-card-text>
    </v-sheet>
</template>

<script>
    import mailService from "@/services/mailService";
    import {VueRecaptcha} from 'vue-recaptcha'

    export default {
        name: "newsletterForm",
        components: {VueRecaptcha},
        data() {
            return {
                content: {
                    en: {
                        submitSuccess: '' +
                            '<h2 class="white--text">Newsletter sign-up received</h2>\n' +
                            '<p class="white--text">Your message has been received<br>We will get back to you ASAP</p>',
                        nameLabel: 'Full name',
                        emailLabel: 'E-mail',
                        subscribeButton: 'Subscribe',
                    },
                    da: {
                        submitSuccess: '' +
                            '<h2 class="white--text">Tilmeldt</h2>\n' +
                            '<p class="white--text">Du er nu tilmeldt vores nyhedsbrev.</p>',
                        nameLabel: 'Dit fulde navn',
                        emailLabel: 'E-mail',
                        subscribeButton: 'Tilmeld',
                    },
                },
                contact: {
                    name: '',
                    email: '',
                    method: 'newsletter'
                },
                loading: false,
                contactSuccess: false,
                invisibleRecaptcha: false,
                sitekey: '6Lfz3Y0iAAAAAE_CooC4T-QSoFLxcyyV17tN4uos',

            }
        },
        methods: {
            onSubmit() {
                this.$refs.invisibleRecaptcha.execute()
            },
            onVerify(verification) {
                this.loading = true
                mailService.send(this.contact, 'contactForm')
                    .then(response => {
                        if (response.data.message === 'mail-service/mail-sent') {
                            this.loading = false;
                            this.contactSuccess = true;
                        }
                    })
            },
            onExpired() {
                console.log('Expired')
            },
            resetRecaptcha() {
                this.$refs.recaptcha.reset() // Direct call reset method
            },
        },
    }
</script>

<style scoped>

</style>