import api from "./api";

class mailService {
    async send(payload, mailType = false) {
        switch (mailType) {
            case false:
            case 'undefined':
                return await new Promise((resolve, reject) => {
                    api.post('/mail-service', payload)
                        .then(response => {
                            resolve(response)
                        })
                        .catch(err => {
                            reject(err)
                        })
                })
                break;
            case 'contactForm':
                return await new Promise((resolve, reject) => {
                    api.post('/mail-service/contact', payload)
                        .then(response => {
                            resolve(response)
                        })
                        .catch(err => {
                            reject(err)
                        })
                })
                break;
            case 'sign-up':
                return await new Promise((resolve, reject) => {
                    api.post('orders/sign-up', payload)
                        .then(response => {
                            resolve(response);
                        })
                        .catch(err => {
                            reject(err);
                        })
                })
                break;
            case 'publisher-signup':
                return await new Promise((resolve, reject) => {
                    api.post('publishers/sign-up', payload)
                        .then(response => {
                            resolve(response);
                        })
                        .catch(err => {
                            reject(err);
                        })
                })
                break;
            default:
                return {status: 'error', message: 'Invalid method'}

        }
    }
}


export default new mailService();