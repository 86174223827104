<template>
    <v-app :class="this.$vuetify.theme.dark ? '' : 'blue-grey lighten-5'">
        <!--div style="position:fixed;top:0;left:0;z-index:10000;color:#fff;">{{$vuetify.breakpoint.name}}</div-->
        <navigation-drawer/>
        <app-bar/>
        <v-main class="grey lighten-4">
            <router-view/>
        </v-main>
        <site-footer/>
        <for-publishers/>
        <snack-bar/>
    </v-app>
</template>

<script>

    import AppBar from "@/components/navigation/appBar";
    import NavigationDrawer from "@/components/navigation/navigationDrawer";
    import SiteFooter from "@/components/UI/siteFooter";
    import ScrollToTop from "@/components/navigation/scrollToTop";
    import ForPublishers from "@/components/UI/forPublishers";
    import Breadcrumbs from "@/components/navigation/breadcrumbs";
    import CookieBot from "@/components/cookieBot";
    import SnackBar from "@/components/toasts/toasts";
    import api from "@/services/api";

    export default {
        name: 'App',
        components: {
            SnackBar,
            CookieBot,
            Breadcrumbs,
            ForPublishers,
            ScrollToTop,
            SiteFooter,
            NavigationDrawer,
            AppBar
        },
        data() {
            return {
                items: []
            }
        },
        computed: {},
        mounted() {
            this.getRoutes()
        },
        methods: {
            getRoutes() {
                api.get('get-routes')
                    .then(response => {
                    })
            }

        }
        /*
        watch: {
            $route: {
                immediate: true,
                handler(to, from) {
                    document.title = to.meta.title || to.name;
                }
            },
        }


         */

    };
</script>
<style>
    @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,400;0,500;0,600;1,400&display=swap');

    h1, h2, h3 {
        font-family: 'Montserrat', Arial, sans-serif;
        font-weight: 700;
    }

    h1 {font-size:calc(38px + (46 - 38) * ((100vw - 300px) / (1600 - 300))) !important;}
    h2 {font-size:calc(32px + (42 - 32) * ((100vw - 300px) / (1600 - 300))) !important;line-height: 1}
    h3 {font-size:calc(28px + (36 - 28) * ((100vw - 300px) / (1600 - 300))) !important;}
    h1 span,
    h2 span,
    h3 span{
        font-size: inherit !important;
    }
    .subHeader{
        font-weight:500;
        font-size:calc(24px + (26 - 24) * ((100vw - 300px) / (1600 - 300))) !important;
    }
    body {
        font-family: 'Work Sans', 'Montserrat', Arial, sans-serif;
        text-decoration: none;
    }

    li {
        line-height: 1.6;
        margin-bottom: 8px;
    }

    .v-main {
    }
    .v-application{

    }
    .v-application input {
        font-family: 'Work Sans', 'Montserrat', Arial, sans-serif;
        line-height: 1.8;
        font-weight: 500;
    }
    .v-application p,
    .v-application li,
    .v-application strong{
        font-family: 'Work Sans', 'Montserrat', Arial, sans-serif;
        font-size:calc(16px + (18 - 16) * ((100vw - 360px) / (1920 - 360)));
        line-height:1.6;
    }

    input[type="text"] {
        padding: 8px !important;
    }

    .v-input__slot:before {

    }

    .align-items-center {
        align-items: center !important;
    }

    .align-items-end {
        align-items: flex-end !important;
    }

    .compact {
        transform: scale(0.95) !important;
        transform-origin: left top;
    }

    .compact--very {
        transform: scale(0.875) !important;
        transform-origin: left top;
    }

    .v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
        box-shadow: inset 0 4px 4px -5px rgba(50, 50, 50, 0.75), inset 0 -4px 5px -5px rgba(50, 50, 50, 0.75)
    }

    .seperator {
        height: 1px;
        width: 66%;
        background: #ff5529;
        margin: 25px auto;
    }

    .separator-100 {
        width: 100%;
        height: 100px;
    }

    .tiptap-vuetify-editor > div {
        box-shadow: none !important;

    }

    .tiptap-vuetify-editor__toolbar {
        border-top: 1px solid #dadada;
        border-right: 1px solid #dadada;
        border-left: 1px solid #dadada;
    }

    .tiptap-vuetify-editor__content {
        border-bottom: 1px solid #dadada;
        border-right: 1px solid #dadada;
        border-left: 1px solid #dadada;
    }

    .tiptap-vuetify-editor div.ProseMirror {
        margin: 0 5px !important;

    }

    .v-card__title {
        word-break: normal !important;
    }
</style>
