<template>

</template>

<script>
    export default {
        name: "cookieBot",
        mounted() {
        }
    }
</script>

<style scoped>

</style>