<template>
    <v-btn color="primary"
           class="pl-4 pr-4 forPublishers pt-0 pb-0"
           dark
           dense
           :to="{path:'/for-publishers'}"
           active-class="primary"
    >
        <v-icon size="16px">mdi-star</v-icon>
        <span class="mb-0 pl-1 "
           style="cursor: pointer">{{content[$store.state.locale].buttonText}}</span>
    </v-btn>

</template>

<script>
    export default {
        name: "forPublishers",
        data() {
            return {
                content: {
                    en: {
                        buttonText: 'For publishers',
                    },
                    da: {
                        buttonText: 'Til publishers',
                    }
                },
            }
        }
    }
</script>

<style scoped>
    .forPublishers {
        position: fixed;
        bottom: 0;
        right: 100px;
        z-index: 1000;
    }
</style>