<template>
    <div class="alertContainer" style="position:fixed;right:5px;bottom:0;z-index: 100000;">
        <v-alert
            v-for="(vendetta,i) in $store.state.toasts.alerts"
            :type="vendetta.type"
            :icon="vendetta.icon"
            :key="vendetta.id"
            border="left"
            elevation="2"
            colored
            dense
            max-width="360"
            style="cursor:pointer"
            transition="scale-transition"
            @click="$store.dispatch('toasts/removeToast',vendetta.id)"
        >{{vendetta.text}}
        </v-alert>
    </div>
</template>

<script>
    export default {
        name: "snackBar",
        methods: {
            log(obj) {
                console.log(obj)
            }
        },
        watch: {
            '$store.state.toasts.alerts': {
                handler() {
                    /*console.log(this.$store.state.toasts.alerts)*/
                }
            }
        }
    }
</script>

<style scoped>

</style>