import Vue from 'vue'
import router from './router'
import VueMeta from 'vue-meta'
import './registerServiceWorker'
import store from './store'
import vuetify from './plugins/vuetify'
import App from './App.vue'
import 'vuetify/dist/vuetify.min.css'
import {initializeApp} from 'firebase/app';
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css'
import 'vuetify/dist/vuetify.min.css'
import AOS from 'aos'
import 'aos/dist/aos.css'
import VueI18n from 'vue-i18n'
import mixins from './mixins/mixins'
require('./ext/ext.js')


initializeApp({
  apiKey: "AIzaSyBXBF0q-BV0fyjsS8bYvljM_oNClZrCQ5Y",
  authDomain: "kaboozt-1a3bc.firebaseapp.com",
  projectId: "kaboozt-1a3bc",
  storageBucket: "kaboozt-1a3bc.appspot.com",
  messagingSenderId: "1099371626721",
  appId: "1:1099371626721:web:62198a056874ebc98377a0"
})
Vue.config.productionTip = false

Vue.use(VueMeta)
Vue.use(VueI18n)
Vue.use(AOS.init())
Vue.config.productionTip = false
Vue.mixin(mixins)
Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: 'mdi'
})

Vue.use(vuetify,{
  treeShake:true
})
export const bus = new Vue()

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
