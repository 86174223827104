<template>
    <v-app-bar
            app
            class="nav--top"
            dark
            elevation="0"
            :color="barBackground"
    >
        <v-row>
            <v-col xl="2"
                   lg="2"
                   class="hidden-md-and-down">
            </v-col>
            <v-col xl="8"
                   lg="8"
                   cols="12">
                <div class="d-flex align-items-center fill-height">

                    <!-- Logo -->
                    <router-link
                            link
                            exact
                            :to="{path:'/'}"
                            style="line-height:1"
                    >
                        <img
                                height="42px"
                                src="/img/kb-lb-logo_light.svg"
                                alt="Professional linkbuilding made easy | Kaboozt"
                        />
                    </router-link>
                    <!-- Logo END -->

                    <v-spacer/>

                    <!-- ROUTES -->
                    <router-link
                            v-for="(anchor,i) in content[$store.state.locale].anchors"
                            :key="i"
                            :to="{path:'/',hash:anchor.anchor}"
                            text
                            class="nav--link pl-8 hidden-sm-and-down white--text"
                    >{{anchor.title}}
                    </router-link>

                    <v-btn
                            href="https://app.kaboozt.com"
                            rounded
                            color="primary"
                            small
                            class="ml-4"
                    >{{content[$store.state.locale].signUpText}}
                    </v-btn>

                    <!-- ROUTES END -->
                    <div class="hidden-lg-and-up hidden-sm-and-down">
                        <a
                                href="https://app.kaboozt.com"
                                class="nav--link pl-8 hidden-sm-and-down white--text"
                        >{{content[$store.state.locale].loginText}}
                        </a>
                        <!--login-dialog v-if="loginAllowed"/-->

                    </div>
                    <v-app-bar-nav-icon
                            class="hidden-md-and-up"
                            @click="$store.state.nav.drawer.show = !$store.state.nav.drawer.show"
                    />

                </div>
            </v-col>
            <v-col xl="2"
                   lg="2"
                   class="hidden-md-and-down">
                <!-- LOGIN BUTTON -->

                <v-col class="d-flex justify-end align-items-center">
                    <a href="https://app.kaboozt.com" class="nav--link pl-8 hidden-sm-and-down white--text"
                    >{{content[$store.state.locale].loginText}}
                    </a>
                    <locale-switch class="pl-4"/>
                </v-col>
                <!-- LOGIN BUTTON END -->
            </v-col>
        </v-row>


    </v-app-bar>
</template>

<script>
    import cartBadge from "../cart/cartBadge";
    import LoginDialog from "../user/loginDialog";
    import NotificationBadge from "../user/notificationBadge";
    import ProfileBadge from "../user/profileBadge";
    import LocaleSwitch from "@/components/locales/localeSwitch";
    import Login from "@/views/Login";

    export default {
        name: "appBar",
        data() {
            return {
                pageName: '',
                scrolled: false,
                loginAllowed: false,
                content: {
                    en: {
                        anchors: [
                            {title: 'HOW IT WORKS', anchor: '#howItWorks',},
                            {title: 'LINK BUILDING', anchor: '#what-is-linkbuilding',},
                            {title: 'CONTENT', anchor: '#content-creation-by-industry-professionals',},
                            {title: 'DISPLAY ADS', anchor: '#digitalDisplayAds'},
                            {title: 'PRICING', anchor: '#pricing',},
                            {title: 'ABOUT', anchor: '#about',},
                        ],
                        signUpText: 'Sign up',
                        loginText: 'Login',

                    },
                    da: {
                        anchors: [
                            {title: 'HVORDAN VIRKER DET', anchor: '#howItWorks',},
                            {title: 'LINKBUILDING', anchor: '#what-is-linkbuilding',},
                            {title: 'CONTENT', anchor: '#content-creation-by-industry-professionals',},
                            {title: 'DISPLAY ADS', anchor: '#digitalDisplayAds'},
                            {title: 'PRIS', anchor: '#pricing',},
                            {title: 'OM', anchor: '#about',},
                        ],
                        signUpText: 'TILMELD',
                        loginText: 'Log ind',
                    }
                },

                options: {
                    duration: 500,
                    easing: 'easeInOutCubic',
                },
                routes: [],
                barBackground: 'menu',

            }
        },
        components: {Login, LocaleSwitch, ProfileBadge, NotificationBadge, LoginDialog, cartBadge},
        methods: {
            handleScroll() {
                let background = 'menu'
                let isHome = (this.$router.currentRoute.path === '/')
                if (isHome && window.pageYOffset === 0) {
                    background = 'transparent'
                }
                this.barBackground = background
            },
        },
        computed: {
            currentCartCounter() {
                return this.$store.state.cartModule.orders.length
            },
        },
        created() {
            window.addEventListener('scroll', this.handleScroll);
        },
        destroyed() {
            window.removeEventListener('scroll', this.handleScroll)
        },
        mounted() {
            this.handleScroll()
            this.pageName = this.$router.currentRoute.name
            this.routes = this.$router.options.routes
        },
        watch: {
            '$route': {
                handler() {
                    this.handleScroll()
                    this.pageName = this.$router.currentRoute.name
                }
            },

        }
    }
</script>

<style>
    header.nav--top,
    header.nav--top > div {
        transition: 225ms ease;
    }

    header.nav--top {
        /*
        background-color: rgba(0, 0, 0, .5) !Important;
        background-color: rgba(255, 255, 255, .1) !Important;
        background-color: rgba(52, 25, 142, 0.5) !important;
        background-color: rgba(255, 85, 41, 1) !important;


         */
        transition: 225ms ease;
    }

    header.nav--top.v-app-bar--is-scrolled,
    header.nav--top.the--app {
        /*
        background-color: rgba(255, 255, 255, .90) !Important;
        background-color: rgba(52, 25, 142, 0.9) !important;
        background-color: rgba(255, 85, 41, 1) !important;

         */
    }

    header .nav--link {
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 14px !important;
        font-family: 'Montserrat', 'Roboto', sans-serif;
    }


    .align-items-center {
        align-items: center;
    }

    .logo--svg {
        padding: 12px;
    }
</style>
