import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import store from '@/store'

Vue.use(Vuetify);
export default new Vuetify({
    theme: {
        dark: store.state.page.theme.dark,
        themes: {
            light: {
                /*primary: '#ff5529',*/
                primary: '#ed631e',
                secondary: '#ff5529',
                accent: '#1dd4bb',
                error: '#e03947',
                warning: '#f99d56',
                menu: '#091e2e',

            },
            dark: {
                primary: '#fd6b28',
                secondary: '#ee8e0a',
                accent: '#1dd4bb',
                error: '#e03947',
                warning: '#f99d56',
                menu: '#091e2e',
            },
        },
    },

});
